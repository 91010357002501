@font-face {
font-family: '__sharonSans_c6681c';
src: url(/_next/static/media/c3a14f70aa971df5-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 100 900;
font-style: normal italic;
}

@font-face {
font-family: '__sharonSans_c6681c';
src: url(/_next/static/media/995a0a0825bee8f0-s.p.otf) format('opentype');
font-display: swap;
font-weight: 400;
font-style: normal;
}@font-face {font-family: '__sharonSans_Fallback_c6681c';src: local("Arial");ascent-override: 94.05%;descent-override: 25.91%;line-gap-override: 0.00%;size-adjust: 104.20%
}.__className_c6681c {font-family: '__sharonSans_c6681c', '__sharonSans_Fallback_c6681c'
}.__variable_c6681c {--font-sans: '__sharonSans_c6681c', '__sharonSans_Fallback_c6681c'
}

@font-face {
font-family: '__sharonDisplay_d25443';
src: url(/_next/static/media/e569c1681f200911-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 100 900;
font-style: normal italic;
}@font-face {font-family: '__sharonDisplay_Fallback_d25443';src: local("Arial");ascent-override: 95.29%;descent-override: 26.25%;line-gap-override: 0.00%;size-adjust: 102.85%
}.__className_d25443 {font-family: '__sharonDisplay_d25443', '__sharonDisplay_Fallback_d25443'
}.__variable_d25443 {--font-display: '__sharonDisplay_d25443', '__sharonDisplay_Fallback_d25443'
}

@font-face {
font-family: '__sharonSerif_a83bb5';
src: url(/_next/static/media/03607caaac0591b2-s.p.otf) format('opentype');
font-display: swap;
font-weight: 700;
font-style: italic;
}@font-face {font-family: '__sharonSerif_Fallback_a83bb5';src: local("Arial");ascent-override: 96.81%;descent-override: 26.67%;line-gap-override: 0.00%;size-adjust: 101.23%
}.__className_a83bb5 {font-family: '__sharonSerif_a83bb5', '__sharonSerif_Fallback_a83bb5';font-weight: 700;font-style: italic
}.__variable_a83bb5 {--font-serif: '__sharonSerif_a83bb5', '__sharonSerif_Fallback_a83bb5'
}

